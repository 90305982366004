@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Andika:ital,wght@0,400;0,700;1,400;1,700&display=swap");
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

@font-face {
    font-family: Alliance;
    src: url("../static/fonts/Alliance.otf") format("opentype");
}
@font-face {
    font-family: GT America;
    src: url("../static/fonts/GT_America.ttf") format("opentype");
}
:root {
    --highlight-color: #00d1f7;
    --off-white: #f0f0f0;
}
html,
body {
    /* overflow-y: visible;
    overflow-x: hidden;
    height: 100vh; */
    font-family: "GT America";
    /* pointer-events: none; */
}

.background {
    z-index: -10;
    height: 100vh;
    width: 100%;
    background: rgb(211, 233, 255);
    background: linear-gradient(0deg, rgba(211, 233, 255, 1) 0%, rgba(255, 243, 241, 1) 100%);
    position: fixed;
    top: 0;
    left: 0;
}
a {
    text-decoration: none;
    color: black;
}
.heroWrapper {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
}
.drone-container {
    position: sticky;
    height: 50vh;
    width: 100%;
    top: 50vh;
    z-index: -1;
    margin-top: 70vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
}

.webgl {
    top: 0;
    left: 0;
    outline: none;
    position: absolute;
    z-index: -9;
    max-width: 100%;
}

.tag-line {
    font-size: 25px;
    font-weight: 900;
    margin-top: 20px;
    margin-left: 5px;
    color: #f0f0f0;
    line-height: 130%;
}
.about-us-container {
    width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 12vh auto;
    transform: translateY(-75vh);
}
.title {
    color: black;
    font-size: 40px;
}
.subtitle {
    color: black;
    font-size: 20px;
    margin-top: 4vh;
    text-align: center;
}

.about-us-description {
    width: 800px;
    text-align: justify;
    margin: 3vh auto;
    font-size: 20px
}

.empty {
    height: 100vh;
}

.fade-in {
    opacity: 0;
    transition: opacity 250ms ease-in;
}
.title-fade-in {
    opacity: 0;
    transition: opacity 250ms ease-in;
}

.appear {
    opacity: 1;
}

.feild-holder {
    position: relative;
    margin: 15px 0;
}
input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: solid var(--off-white) 2px;
    outline: none;
    transition: 0.3s ease-in-out;
    padding: 0 10px;
}

label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in-out;
}

label,
input {
    font-size: 20px;
    width: 100%;
    font-size: 20px;
    height: 55px;
    cursor: text;
}

input:focus + label,
input:valid + label {
    top: -25px;
    left: -5px;
    pointer-events: none;
    font-size: 15px;
    font-weight: bold;
    color: var(--off-white);
}

input:focus,
input:valid {
    border-bottom: solid var(--off-white) 5px;
}

.form-submit {
    background-color: black;
    color: var(--off-white);
    border: none;
    width: 50%;
    height: 50px;
    font-size: 20px;
    font-family: "GT America";
}

.form-submit:hover {
    background-color: var(--off-white);
    color: black;
}

.form-submit:active {
    scale: 90%;
}

.align-content-center {
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
}
.careers {
    color: var(--off-white);
    font-size: large;
    font-weight: 500;
    margin: 10px 10px;
}


.footer {
    background-color: black;
    width: 100%;
}

.footer-container {
    width: 80%;
    margin: 0 auto;
    padding: 3vh 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
}
.footer-item {
    flex: 50%;
    width: 100%;
    padding: 1vh 0;
}

.footer-company {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
}

.footer-logo {
    height: 80px;
    width: 80px;
    border-radius: 50px;
}

.footer-title {
    color: var(--off-white);
    padding-left: 20px;
}


.footer-icon {
    color: var(--off-white);
    font-size: 30px;
    width: 50px;
    text-align: center;
}
.footer-contact-item{
    color: var(--off-white);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    margin: 15px 0;
}

.footer-contact-item span {
    margin: 5px 5px;
}

.social-icon {
    font-size: 45px;
    margin: 5px 10px;
}
.canvas2 {
    max-width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.trottle-container {
    position: absolute;
    width: 9vw;
    top: 3vh;
    left: 2vw;
    height: 75%;
    border: solid 3px rgba(200, 200, 200, 0.8);
    border-radius: 5%;
}

.trottle {
    position: absolute;
    background-color: rgba(200, 200, 200, 0.8);
    width: 3vw;
    margin: 0;
    padding: 0;
    border-radius: 5%;
}

.trottle-title {
    position: absolute;
    width: 9vw;
    top: 2%;
    text-align: center;
    font-size: 18px;
}

.trottle1 {
    bottom: 47%;
    left: 1vw;
    height: 47%;
}

.trottle2 {
    bottom: 47%;
    left: 5vw;
    height: 10%;
}

.trottle3 {
    bottom: 3%;
    left: 1vw;
    height: 10%;
}

.trottle4 {
    bottom: 3%;
    left: 5vw;
    height: 10%;
}

.email-response-container {
    position: fixed;
    top: 10px;
    right: 50px;
    height: 30px;
    width: 300px;
    background-color: var(--off-white);
    padding: 10px;
    border-radius: 10px;
    opacity: 0;
    transition: 500ms ease-in;
}

.email-response-container.appear {
    transform: translateY(10px);
    opacity: 0.9;
}

.projects-section {
    width: 100%;
    background-color: #d3d3d3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5vh 0;
}
.swiper {
    margin-top: 4vh;
    width: 800px;
}

.project-item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 100px;
    flex-wrap: wrap;
}

.project-video-landscape {
    width: 500px;
}
.project-video-verticle {
    height: 400px;
}

.project-description {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.project-description > p {
    margin: 20px auto;
    text-align: justify;
}

.social-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.social-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.hero-text-container {
    position: absolute;
    margin: 27vh 10vw;
}

.hero-text {
    font-size: 90px;
    color: rgba(239, 239, 239, 255);
    line-height: 95%;
}

.form-section {
    display: block;
    position: relative;
    max-width: 100%;
    padding: 100px 10vw;
    display: flex;
    flex-direction: row;
    background-color: var(--highlight-color);
}
.form-title {
    margin-top: 50px;
    padding-left: 50px;
    font-size: 40px;
    flex: 50%;
}
.careers-floater {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 50px;
    background-color: orange;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.form {
    display: flex;
    flex-direction: column;
    flex: 50%;
}

.team-section {
    width: 100%;
    background-color: #E3E3E3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 0;
}

.team-container {
    max-width: 80%;
}

.highlight {
    color: #00d1f7;
}
.next-line {
    display: none;
}
.we-provide-container {
    margin: 1vh auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 80%;
    flex-wrap: wrap;
}
.we-provide-item-title {
    font-size: 20px;
    margin: 2vh auto;
    text-align: center;
}
.we-provide-item {
    min-width: 200px;
    padding: 20px 40px;
    flex: 33%;
    max-width: 450px;
}
.we-provide-item-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.we-provide-item-description {
    text-align: justify;
}

.ache-desc-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.ache-desc-item {
    flex: 50%;
    padding: 10px 20px;
}
.ache-image-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ache-image {
    margin: auto;
    width: 500px;
}
.ache-subtitle {
    color: black;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
}


@media screen and (max-width: 1000px) {
    .hero-text-container {
        margin: 10vh 5vw;
    }
    .hero-text {
        font-size: 70px;
    }
}

@media screen and (max-width: 850px) {
    .swiper {
        width: 400px;
    }
    .project-item {
        padding: 10px 50px;
    }
    .project-video-landscape {
        width: 300px;
    }
    .project-video-verticle {
        height: 300px;
    }
    
}

@media screen and (max-width: 900px) {
    .form-section {
        flex-direction: column;
        padding: 50px 10vw;
        height: fit-content;
        justify-content: start;
    }
    .form-title {
        flex: 100%;
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: -170px;
        padding: 0;
    }
    .form {
        flex: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
        padding-top: 100px;
    }
    .form-submit {
        margin-top: 30px;
        align-self: center;
    }
    .trottle-title {
        font-size: 12px;
    }

    .about-us-description {
        width: 500px;
    }  
    
}

@media screen and (max-width: 650px) {
    .footer-container {
        flex-direction: column;
    }
    .footer-contact-item {
        margin: 15px 7px;
    }
    .ache-image {
        width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .trottle-title {
        font-size: 10px;
    }
    .trottle-container {
        height: 60%;
    }
    .about-us-description {
        width: 400px;
        font-size: 15px;
    }
    .next-line {
        display: inline-block;
    }
}

@media screen and (max-width: 480px) {
    .hero-text-container {
        margin: 5vh 5vw;
    }
    .hero-text {
        font-size: 55px;
    }
    .trottle-container {
        height: 40%;
    }
    .trottle-title {
        display: none;
    }

    .details-title > h1 {
        font-size: 25px;
    }
    .careers-floater {
        width: 100%;
        justify-content: center;
    }
    .about-us-description {
        width:  280px;
        font-size: 15px;
    }
    .swiper {
        width: 350px;
    }
    .project-video-landscape {
        width: 250px;
    }
    .project-video-verticle {
        height: 300px;
    }
    .ache-image {
        width: 300px;
    }
    
}




@media screen and (min-height: 750px) {
    .details {
        height: 200px;
    }
    
}
